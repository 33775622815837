<template>
  <div class="c-app">
    <CWrapper>
      <MsiHeader />
      <div class="c-body">
        <main class="c-main d-flex flex-column p-0">
          <CContainer fluid class="d-flex flex-column flex-grow-1 p-0">
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <MsiFooter />
    </CWrapper>
  </div>
</template>

<script>
import {
  CWrapper,
  CContainer
} from '@coreui/vue';

import MsiHeader from './MsiHeader.vue'
import MsiFooter from './MsiFooter.vue'

export default {
  name: 'MsiContainer',
  components: {
    CWrapper,
    CContainer,
    MsiHeader,
    MsiFooter
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
