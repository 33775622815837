<template>
  <CHeader fixed with-subheader light>
    <CHeaderBrand class="ml-3 mr-auto" to="/">
      <img src="img/brand/msi_logo.svg" height="24" alt="Logo"/>
    </CHeaderBrand>

    <CHeaderNav>
      <MsiHeaderDropdownAccnt />
    </CHeaderNav>
  </CHeader>
</template>

<script>
import {
  CHeader,
  CHeaderBrand,
  CHeaderNav
} from '@coreui/vue';

import MsiHeaderDropdownAccnt from './MsiHeaderDropdownAccnt.vue';

export default {
  name: 'MsiHeader',
  components: {
    CHeader,
    CHeaderBrand,
    CHeaderNav,
    MsiHeaderDropdownAccnt
  }
};
</script>
