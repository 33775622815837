<template>
  <CFooter :fixed="true">
    <div class="mfs-auto">
      <span class="mr-1" target="_blank">Powered by</span>
      <a href="https://morgansolar.com/">Morgan Solar</a>
    </div>
  </CFooter>
</template>

<script>
import {
  CFooter
} from '@coreui/vue';

export default {
  name: 'MsiFooter',
  components: {
    CFooter
  }
}
</script>
