<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <CIcon name="cil-user" size="xl" />
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div">
      <div class="user-header d-flex flex-wrap justify-content-center">
        <div class="mr-1">Signed in as</div>
        <div class="user-name"><strong>{{ username }}</strong></div>
      </div>
    </CDropdownHeader>
    <CDropdownDivider class="my-0"></CDropdownDivider>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>Account</strong>
    </CDropdownHeader>
    <CDropdownItem href="https://accounts.morgansolar.com/auth/realms/meerkat/account/" target="_blank">
      <CIcon name="cil-user" size="sm" />
      <span class="ml-1">Profile</span>
    </CDropdownItem>
    <CDropdownItem @click="handleLogout">
      <CIcon name="cil-account-logout" size="sm" />
      <span class="ml-1">Logout</span>
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import {
  CDropdown,
  CHeaderNavLink,
  CIcon,
  CDropdownHeader,
  CDropdownDivider,
  CDropdownItem
} from '@coreui/vue';

export default {
  name: 'MsiHeaderDropdownAccnt',
  components: {
    CDropdown,
    CHeaderNavLink,
    CIcon,
    CDropdownHeader,
    CDropdownDivider,
    CDropdownItem
  },
  data() {
    return {
      username: ''
    };
  },
  methods: {
    handleLogout() {
      this.$kauth.logout();
    }
  },
  async mounted() {
    await this.$kauth.getValidToken();
    this.username = this.$kauth.idTokenParsed.given_name;
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }

  .user-header {
    max-width: 200px;
  }

  .user-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
</style>